import {
  string,
  union,
  type,
  literal,
  TypeOf,
  nullType,
  intersection,
  partial,
  boolean,
} from "io-ts";
import { BooleanFromString } from "io-ts-types/BooleanFromString";
import { NonEmptyString } from "io-ts-types/NonEmptyString";

import { dateFromStringCodec } from "./date";
import { jsonStringCodec } from "./json";

import { stringEnumCodec } from ".";

/**
 * For requests originating from the frontend, codec for state parameter passed
 * through Auth0's `/authenticate` endpoint
 *
 * Not to be confused with @see backendAuthStateCodec , which is for Auth0
 * authenticate calls that originate from the backend
 *
 * @see forwardCsrfTokenThroughAuth0 middleware for how this is used to satisfy
 * our API's CSRF check despite going through Auth0 first
 */
export const frontendAuthStateCodec = jsonStringCodec.pipe(
  intersection([
    type({
      /**
       * CSRF token generated by the backend to be sent back by the frontend;
       * sent via state parameter because unlike all other API requests, we can't
       * forward the csrf header through Auth0 like we generally do
       *
       * If null, was not present; this is expected on dev
       */
      csrfToken: union([NonEmptyString, nullType]),
      /**
       * CSRF token generated by the frontend to be sent back by the backend;
       * necessary to ensure that frontend behavior triggered after the
       * authention attempt is protected from CSRF attacks, like redirecting after
       * login
       */
      frontendStateCsrfToken: NonEmptyString,
    }),
    partial({
      /**
       * If present on social login, causes the guest account to become
       * associated with the social account
       */
      guestToken: string,
      /**
       * If present, indicates that we should only require the user to go through the
       * condensed sign up flow.
       */
      condensedSignUpFlow: boolean,
      skipWelcomeModal: boolean,
      loginAfterDonationFlow: boolean,
    }),
  ])
);

/**
 * Identity providers (IdPs) we currently support (via Auth0)
 */
export enum IdentityProvider {
  USERNAME_PASSWORD = "auth0",
  FACEBOOK = "facebook",
  GOOGLE = "google-oauth2",
}

/**
 * Codec for identity providers (IdPs) we currently support (via Auth0)
 * @see IdentityProvider
 */
export const identityProviderCodec = stringEnumCodec({
  name: "IdentityProvider",
  enumObject: IdentityProvider,
});

/**
 * Codec for data returned to prompt the client to link the existing IdP for the
 * existing email.
 */
export const linkIdpDataCodec = type({
  idp: identityProviderCodec,
  email: string,
});
export type LinkIdpData = TypeOf<typeof linkIdpDataCodec>;

/**
 * Potential errors when linking accounts
 */
export enum LinkAccountErrorCode {
  /**
   * Account to link already exists on a different user
   */
  ACCOUNT_EXISTS = "ACCOUNT_EXISTS",
  /**
   * Can't link an account to itself
   */
  SELF_LINK = "SELF_LINK",
  /**
   * Account trying to link is already an account of that IdP type.
   * e.g. We don't allow linking two Facebook accounts together
   */
  ALREADY_LINKED = "ALREADY_LINKED",
}

/**
 * Codec for potential errors when linking accounts
 * @see LinkErrorCode
 */
export const linkAccountErrorCodec = stringEnumCodec({
  name: "LinkErrorCode",
  enumObject: LinkAccountErrorCode,
});

/**
 * Codec for shape of result for account linking
 */
export const linkAccountResultCodec = union([
  type({ success: BooleanFromString.pipe(literal(true)) }),
  type({
    success: BooleanFromString.pipe(literal(false)),
    errorCode: linkAccountErrorCodec,
  }),
]);

/**
 * Cookie which, if present, identifies that a session exists for the user
 */
export const SESSION_MAX_AGE_DAYS = 365;
export const SESSION_MAX_AGE_MS = SESSION_MAX_AGE_DAYS * 24 * 60 * 60 * 1000;
export const ADMIN_SESSION_MAX_AGE_DAYS = 28;
export const SUPER_ADMIN_SESSION_MAX_AGE_DAYS = 1;

export const sessionExistsCookieCodec = jsonStringCodec.pipe(
  type({ createdAt: dateFromStringCodec })
);
