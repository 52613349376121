import { Card } from "@components/Card";
import { ContactUsLink } from "@components/Link";
import { Loading } from "@components/LoadingIndicator";
import { NonprofitCard } from "@components/feed/NewNonprofitCard";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { MasonryList } from "@components/layout/MasonryList";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useState, useEffect, useContext } from "react";

import { NonprofitResponse } from "@every.org/common/src/codecs/entities";
import { formatEinWithDash } from "@every.org/common/src/entity/types/ein";
import { SkipInt, TakeInt } from "@every.org/common/src/routes/index";

import { ReactComponent as NonprofitAvatar } from "src/assets/illustrations/nonprofit_color.svg";
import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { fetchHomeFeed } from "src/context/DonationsContext/actions";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

interface ArchivedNonprofitPageProps {
  nonprofit: ContextNonprofit;
}

const infoPageSectionContentCss = css`
  display: flex;
  justify-content: center;
  padding-top: ${spacing.m};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding-top: ${spacing.xl};
    `,
  })}
`;

const StyledCard = styled(Card)`
  ${verticalStackCss.l};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding: ${spacing.xxxxl};
    `,
  })}
  align-items: center;
`;

const NonprofitInfoContainer = styled.div`
  ${verticalStackCss.xs};
  color: var(${colorCssVars.text.secondary});
  text-align: center;
  max-width: 280px;
  align-items: center;
`;

const ArchivedNonprofitPage = ({ nonprofit }: ArchivedNonprofitPageProps) => {
  const [feedNonprofits, setFeedNonprofits] = useState<NonprofitResponse[]>([]);
  const authState = useContext(AuthContext);

  useEffect(() => {
    const fetchFeed = async () => {
      const res = await fetchHomeFeed(
        {
          take: 6 as TakeInt,
          skip: 0 as SkipInt,
        },
        authState.status === AuthStatus.LOGGED_OUT
      );
      setFeedNonprofits(res.nonprofits);
    };

    if (authState.status !== AuthStatus.LOADING) {
      fetchFeed();
    }
  }, [authState]);

  return (
    <DefaultPageLayout
      pageTitle={nonprofit.name}
      pageContentCss={verticalStackCss.xxxl}
    >
      <PageSection contentCss={infoPageSectionContentCss}>
        <StyledCard>
          <NonprofitInfoContainer css={verticalStackCss.s}>
            <NonprofitAvatar />
            <div css={verticalStackCss.none}>
              <span>{nonprofit.name}</span>
              {nonprofit.ein && (
                <span>EIN: {formatEinWithDash(nonprofit.ein)}</span>
              )}
            </div>
          </NonprofitInfoContainer>
          <div css={[verticalStackCss.xxs, { maxWidth: "400px" }]}>
            <h1 css={textSizeCss.s}>
              We don’t currently support donations to this nonprofit.
            </h1>
            <p>
              If you are an admin of this nonprofit and would like it to be
              supported, <ContactUsLink />.
            </p>
          </div>
        </StyledCard>
      </PageSection>
      {feedNonprofits.length ? (
        <PageSection contentCss={verticalStackCss.l}>
          <h3>Try these instead</h3>
          <MasonryList
            items={feedNonprofits.map((nonprofit) => ({
              key: nonprofit.id,
              elem: (
                <NonprofitCard
                  nonprofit={nonprofit}
                  causeCategoryBackgroundColor
                />
              ),
            }))}
          />
        </PageSection>
      ) : (
        <Loading />
      )}
    </DefaultPageLayout>
  );
};

export default ArchivedNonprofitPage;
