import { MYRIAD_NONPROFIT_ID } from "@every.org/common/src/entity/constants";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { logger } from "src/utility/logger";
import { hasParentId } from "src/utility/nonprofit";

export const EXCEPTION_NONPROFITS_SLUG_LIST = new Set([
  "globalemergencyresponse",
  "care",
  "international-medical-corps",
  "irc",
  "mercy-corps",
  "oxfam-america",
  "planusa",
  "savethechildren",
  "world-vision",
  "doctors-without-borders",
  "redcross",
  "worldcentralkitchen",
  "icrc",
  "democracy4russia",
  "united4iran",
  "globaldetentionproject",
  "forbidden-colours-be",
  "legionhonduras",
  "disasterphilanthropy",
  "samsfdn",
  "rsjc",
]);

const SANCTIONS_WORDS = [
  "cuba",
  "cuban",
  "iran",
  "iranian",
  "north korea",
  "russia",
  "russian",
  "crimea",
  "donetsk",
  "luhansk",
  "syria",
  "syrian",
];

const SANCTIONS_WORDS_REGEX = new RegExp(
  "\\b(" + SANCTIONS_WORDS.join("|") + ")\\b",
  "i"
);

export function sanctionsWordsChecker(
  text: string,
  fieldName: string,
  nonprofit?: ContextNonprofit,
  userId?: string
) {
  if (
    nonprofit &&
    (EXCEPTION_NONPROFITS_SLUG_LIST.has(nonprofit.primarySlug) ||
      hasParentId(nonprofit, MYRIAD_NONPROFIT_ID))
  ) {
    return false;
  }

  const isInvalid = SANCTIONS_WORDS_REGEX.test(text);
  if (isInvalid) {
    logger.warn({
      message:
        "System has detected there is a chance this update would violate US sanctions",
      data: {
        userId,
        fieldName,
        fieldUpdate: text,
      },
    });
  }

  return isInvalid;
}
