import { NonprofitType } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

export function hasParentId(nonprofit: ContextNonprofit, parentId: string) {
  return nonprofit?.eligibleDonationRecipientNonprofitIds?.includes(
    parentId as any
  );
}

export function isArchivingFeatureAllowed(nonprofit: ContextNonprofit) {
  return [
    NonprofitType.NONPROFIT,
    NonprofitType.PROJECT,
    NonprofitType.PROJECT_WITH_DISBURSEMENT,
    NonprofitType.FUND,
  ].includes(nonprofit.type);
}
